import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

export interface ExternApi {
  id?: string;
  name: string;
  enabled: boolean;
}
@Injectable({
  providedIn: 'root'})
export class ExternApisService {
  private apiUrl = 'https://gateway.manajero.com/administration/ExternalApis';

  constructor(private http: HttpClient) {}

  addExternalApi(externalApi: ExternApi): Observable<ExternApi> {
    return this.http.post<ExternApi>(`${this.apiUrl}/addExternApi`, externalApi);
  }

  toggleEnabledById(id: string): Observable<ExternApi> {
    return this.http.put<ExternApi>(`${this.apiUrl}/toggle/${id}`, {});
  }
  getApiStatusByName(apiName: string): Observable<ExternApi> {
    return this.http.get<ExternApi>(`${this.apiUrl}/findByName/${apiName}`);
  }
  getAllExternalApis(): Observable<ExternApi[]> {
    return this.http.get<ExternApi[]>(`${this.apiUrl}/AllList`);
  }
  deleteExternalApi(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/DeleteApi/${id}`);
  }

}
